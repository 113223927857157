import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AuthService } from './services/auth/auth.service';
import { Router } from '@angular/router';
import { ToastService } from './services/toast/toast.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent implements OnInit {
  version = 'v2.0.25';
  passwordNew: string = '';
  passwordVerify: string = '';
  isAuthenticated: boolean = false;
  submenu: boolean = false;
  showPass1: boolean = false;
  showPass2: boolean = false;
  @ViewChild('aside', { static: false }) sidebar: ElementRef | undefined;
  @ViewChild('max', { static: false }) maxSidebar: ElementRef | undefined;
  @ViewChild('mini', { static: false }) miniSidebar: ElementRef | undefined;
  @ViewChild('maxToolbar', { static: false }) maxToolbar: ElementRef | undefined;
  @ViewChild('logo', { static: false }) logo: ElementRef | undefined;
  @ViewChild('content', { static: false }) content: ElementRef | undefined;
  @ViewChild('moon', { static: false }) moon: ElementRef | undefined;
  @ViewChild('sun', { static: false }) sun: ElementRef | undefined;
  @ViewChild('modal', { static: false }) modal: ElementRef | undefined;
  @ViewChild('bgmodal', { static: false }) bgmodal: ElementRef | undefined;

  constructor(
    private auth: AuthService,
    public toast: ToastService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.isAuthenticated = this.auth.isValid();
    this.auth.authChange.subscribe({
      next: (res: any) => {
        this.isAuthenticated = res;
        this.authenticateUser();
      }
    })
    this.authenticateUser();
  }

  ngAfterViewInit(): void {
    this.authenticateUser();
  }

  authenticateUser() {
    if (!this.isAuthenticated) {
      this.router.navigateByUrl('auth/log-masuk');
    } else {
      if (window.location.pathname === '/auth/log-masuk') {
        this.router.navigateByUrl('dashboard/utama');
      }
    }
  }

  setDark(val: any) {
    if (val === "dark") {
      document.documentElement.classList.add('dark');
      this.moon?.nativeElement.classList.add("hidden");
      this.sun?.nativeElement.classList.remove("hidden");
    } else {
      document.documentElement.classList.remove('dark');
      this.sun?.nativeElement.classList.add("hidden");
      this.moon?.nativeElement.classList.remove("hidden");
    }
  }

  openModal() {
    this.submenu = false;
    if (this.modal?.nativeElement.classList.contains('hidden')) {
      this.modal?.nativeElement.classList.remove("hidden");
      this.bgmodal?.nativeElement.classList.remove("hidden");
    } else {
      this.modal?.nativeElement.classList.add("hidden");
      this.bgmodal?.nativeElement.classList.add("hidden");
    }
  }

  openNav() {
    if (this.sidebar?.nativeElement.classList.contains('-translate-x-48')) {
      // buka sidebar
      this.sidebar?.nativeElement.classList.remove("-translate-x-48");
      this.sidebar?.nativeElement.classList.add("translate-x-none");
      this.maxSidebar?.nativeElement.classList.remove("hidden");
      this.maxSidebar?.nativeElement.classList.add("flex");
      this.miniSidebar?.nativeElement.classList.remove("flex");
      this.miniSidebar?.nativeElement.classList.add("hidden");
      this.maxToolbar?.nativeElement.classList.add("translate-x-0");
      this.maxToolbar?.nativeElement.classList.remove("translate-x-24", "scale-x-0");
      this.logo?.nativeElement.classList.remove("ml-12");
      this.content?.nativeElement.classList.remove("ml-12");
      this.content?.nativeElement.classList.add("ml-12", "md:ml-60");
    } else {
      // tutup sidebar
      this.sidebar?.nativeElement.classList.add("-translate-x-48");
      this.sidebar?.nativeElement.classList.remove("translate-x-none");
      this.maxSidebar?.nativeElement.classList.add("hidden");
      this.maxSidebar?.nativeElement.classList.remove("flex");
      this.miniSidebar?.nativeElement.classList.add("flex");
      this.miniSidebar?.nativeElement.classList.remove("hidden");
      this.maxToolbar?.nativeElement.classList.add("translate-x-24", "scale-x-0");
      this.maxToolbar?.nativeElement.classList.remove("translate-x-0");
      this.logo?.nativeElement.classList.add('ml-12');
      this.content?.nativeElement.classList.remove("ml-12", "md:ml-60");
      this.content?.nativeElement.classList.add("ml-12");

    }
  }

  openDropDown(id: any) {
    document.querySelector(`#submenu${id}`)?.classList.toggle("max-h-40");
    document.querySelector(`#arrow${id}`)?.classList.toggle("rotate-90");
  }

  showPassword(no: any) {
    (this as any)[`showPass${no}`] = !(this as any)[`showPass${no}`];
  }

  showToast() {
    this.toast.add('This is a toast message.', 5000, 'success');
  }

  removeToast(index: number) {
    this.toast.remove(index);
  }

  logout() {
    this.auth.logout();
  }
}