@if (showDialog) {
<div class="relative h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8 bg-gray-500 bg-no-repeat bg-cover relative items-center"
    style="background-image: url(../../../../assets/e-kan-mosaic.png);">
    <div class="min-w-screen relative flex min-h-screen flex-col items-center justify-center bg-white pt-4">
        <div class="fixed inset-0 bg-gray-600 bg-opacity-75 transition-opacity"></div>
        <div class="fixed overflow-y-auto">
            <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">

                <div
                    class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                    <div class="p-4 sm:p-10 text-center overflow-y-auto">

                        <span
                            class="mb-4 inline-flex justify-center items-center w-[62px] h-[62px] rounded-full border-4 border-yellow-50 bg-yellow-100 text-yellow-500">
                            <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                fill="currentColor" viewBox="0 0 16 16">
                                <path
                                    d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z">
                                </path>
                            </svg>
                        </span>

                        <h3 class="mb-2 text-2xl font-bold text-gray-800">
                            Hubungi Kami
                        </h3>
                        <div class="text-gray-500">
                            <p>Untuk menukar kata laluan, sila hubungi kami untuk membuat permohonan kata laluan baru.
                            </p>
                            <p class="font-bold">Tel: 088-294301 Fax: 088-233076</p>
                        </div>

                        <div class="mt-6 flex justify-center gap-x-4">
                            <button (click)="showDialog = false" type="button"
                                class="py-2.5 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all text-sm">
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
} @else {
<div class="relative min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8 bg-gray-500 bg-no-repeat bg-cover relative items-center"
    style="background-image: url(../../../../assets/e-kan-mosaic.png);">
    <div class="absolute bg-black opacity-60 inset-0 z-0"></div>
    <div class="max-w-md w-full space-y-8 p-10 bg-white rounded-xl z-10">
        <div class="flex justify-center">
            <img src="../../../../assets/logo/konelayan-ekan-logo.png" class="w-20 h-20" alt="ekan center logo">
        </div>
        <div class="text-center">
            <h2 class="mt-6 text-3xl font-bold text-gray-900">
                Ekan Merchant
            </h2>
            <p class="mt-2 text-sm text-gray-600">Log Masuk</p>
        </div>
        <form [formGroup]="loginForm" class="mt-8 space-y-6" (ngSubmit)="submitLogin()">
            <div class="relative">
                <label class="text-sm font-bold text-gray-700 tracking-wide">
                    NO TEL BIMBIT
                    <span class="text-red-600">*</span>
                </label>
                <input
                    class=" w-full text-base py-2 border-b border-gray-300 focus:outline-none focus:border-indigo-500"
                    type="tel" formControlName="mobile_phone_no" placeholder="01922822222">
            </div>
            <div class="relative mt-8 content-center">
                <label class="text-sm font-bold text-gray-700 tracking-wide">
                    KATA LALUAN
                    <span class="text-red-600">*</span>
                </label>
                <input
                    class="w-full content-center text-base py-2 border-b border-gray-300 focus:outline-none focus:border-indigo-500"
                    [type]="showPass ? 'text' : 'password'" formControlName="password"
                    placeholder="MASUKAN KATA LALUAN ANDA">
                <span class="absolute right-0 -translate-x-5 z-50">
                    @if (!showPass) {
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                        class="duration-500 cursor-pointer size-6" (click)="showPassword()">
                        <path
                            d="M3.53 2.47a.75.75 0 0 0-1.06 1.06l18 18a.75.75 0 1 0 1.06-1.06l-18-18ZM22.676 12.553a11.249 11.249 0 0 1-2.631 4.31l-3.099-3.099a5.25 5.25 0 0 0-6.71-6.71L7.759 4.577a11.217 11.217 0 0 1 4.242-.827c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113Z" />
                        <path
                            d="M15.75 12c0 .18-.013.357-.037.53l-4.244-4.243A3.75 3.75 0 0 1 15.75 12ZM12.53 15.713l-4.243-4.244a3.75 3.75 0 0 0 4.244 4.243Z" />
                        <path
                            d="M6.75 12c0-.619.107-1.213.304-1.764l-3.1-3.1a11.25 11.25 0 0 0-2.63 4.31c-.12.362-.12.752 0 1.114 1.489 4.467 5.704 7.69 10.675 7.69 1.5 0 2.933-.294 4.242-.827l-2.477-2.477A5.25 5.25 0 0 1 6.75 12Z" />
                    </svg>
                    } @else {
                    <svg (click)="showPassword()" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                        fill="currentColor" class="cursor-pointer size-6">
                        <path d="M12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                        <path fill-rule="evenodd"
                            d="M1.323 11.447C2.811 6.976 7.028 3.75 12.001 3.75c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113-1.487 4.471-5.705 7.697-10.677 7.697-4.97 0-9.186-3.223-10.675-7.69a1.762 1.762 0 0 1 0-1.113ZM17.25 12a5.25 5.25 0 1 1-10.5 0 5.25 5.25 0 0 1 10.5 0Z"
                            clip-rule="evenodd" />
                    </svg>
                    }

                </span>
            </div>
            <div class="flex items-center justify-between">
                <div class="text-sm">
                    <a class="font-medium text-indigo-500 hover:text-indigo-500 cursor-pointer"
                        (click)="showDialog = true">
                        LUPA KATA LALUAN?
                    </a>
                </div>
            </div>
            <div>
                <button type="submit"
                    class="w-full flex justify-center bg-indigo-500 text-gray-100 p-4  rounded-full tracking-wide
                                font-semibold  focus:outline-none focus:shadow-outline hover:bg-indigo-600 shadow-lg cursor-pointer transition ease-in duration-300">
                    LOG MASUK
                </button>
            </div>
        </form>
        <div class="flex justify-center">
            <img src="../../../../assets/logo/sabah-maju-jaya.png" class="w-15 h-20" alt="logo sabah maju jaya">
            <img src="../../../../assets/logo/kementerian-maf.png" class="size-20 mx-10" alt="logo maf">
            <img src="../../../../assets/logo/konelayan-logo.png" class="size-20" alt="logo sabah maju jaya">
        </div>
    </div>
</div>
}