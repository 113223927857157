import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../../../services/auth/auth.service';
import { ToastService } from '../../../services/toast/toast.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.css'
})
export class LoginComponent implements OnInit, OnDestroy{
  loginForm = this.fb.group({
    mobile_phone_no: [null, Validators.required],
    password: [null, Validators.required]
  });

  showDialog: boolean = false;
  showPass: boolean = false;

  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    public toast: ToastService
  ) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  submitLogin() {
    if (this.loginForm.valid) {
      this.auth.login(this.loginForm.value?.mobile_phone_no!, this.loginForm.value?.password!);
    } else {
      this.toast.add('Sila masukkan No Telefon dan Kata Laluan untuk Log Masuk!', 5000, 'error');
    }
  }


  showPassword() {
    this.showPass = !this.showPass;
  }
}
