import { Injectable } from '@angular/core';
import { ApiService } from '../http/api.service';
import { Subject } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';
import { ToastService } from '../toast/toast.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  authChange = new Subject<boolean>

  constructor(
    private apiService: ApiService,
    private jwtHelper: JwtHelperService,
    private router: Router,
    public toast: ToastService
  ) {}

  validateUser() {
    const token = this.jwtHelper.tokenGetter() as string;
    if (token) {
      if (this.jwtHelper.isTokenExpired(token)) {
        this.logout();
      }
    }
  }

  isValid():boolean {
    const token = this.jwtHelper.tokenGetter() as string;
    const u = this.jwtHelper.decodeToken(token);

    if (u) {
      return true
    } else {
      return false
    }
  }

  login(phone_no: number, password: string) {
    const payload = {
      "phone_no": phone_no,
      "password": password
    }

    this.apiService.login(payload).subscribe({
      next: (res: any) => {
        const token = res.access_token;
        if (token) {
         const u = this.jwtHelper.decodeToken(token);
         if (u.phone_no) {
          localStorage.setItem('client_token', token);
          this.authChange.next(true);
          location.reload();
         }
        }
      },
      error: (err: any) => {
        this.toast.add('Salah No Telefon atau Kata Laluan!', 5000, 'error');
      }
    })
  }

  logout() {
    localStorage.removeItem('client_token');
    this.authChange.next(false);
    this.router.navigateByUrl('auth/log-masuk');
    location.reload();
  }
}
